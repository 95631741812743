import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { navigate } from "gatsby"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import MomentUtils from "@date-io/moment"
import TextField from "@material-ui/core/TextField"
import { ButtonStyled } from "../components/styles/styles"
import styled from "styled-components"
import Layout from "../components/layout"
import { fetchEducator, saveEducator } from "../redux/firebaseAction"

const UpdateEducatorInfoPage = () => {
  const dispatch = useDispatch()
  const { email, educator } = useSelector(state => ({
    ...state.mainReducer.user.user,
    ...state.mainReducer,
  }))
  const [error, setError] = React.useState(false)
  const [form, setForm] = React.useState({
    name: educator.name || "",
    email: educator.email || "",
    gender: educator.gender || "",
    location: educator.location || "",
  })
  const handleChange = event => {
    setForm({ ...form, [event.target.name]: event.target.value })
  }
  const Redirect = () => {
    navigate("/settings", { state: {} })
  }
  const handleSave = form => {
    if (form.name.length !== 0 && form.email.length !== 0) {
      dispatch(saveEducator(form))
      dispatch(fetchEducator(email))
      Redirect()
    } else {
      console.log("form fields are required")
      setError(true)
    }
  }
  const fetchEdu = () => dispatch(fetchEducator(email))

  useEffect(() => {
    fetchEdu()
  }, [])

  const handleClose = () => {
    setForm({
      name: "",
      email: "",
      gender: "",
      location: "",
    })
  }

  return (
    <>
      <Layout>
        <CustomDiv>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <table style={{ marginTop: "10px" }}>
              <tbody>
                <CustomTr>
                  <td>Settings</td>
                  <td></td>
                </CustomTr>
              </tbody>
            </table>
            <CustomForm>
              <form noValidate autoComplete="off">
                <TextField
                  id="standard-basic"
                  name="name"
                  value={form.name}
                  onChange={handleChange}
                  label="Name"
                  variant="outlined"
                />
                <TextField
                  id="standard-basic"
                  name="gender"
                  value={form.gender}
                  onChange={handleChange}
                  label="Gender"
                  variant="outlined"
                />
                <TextField
                  disabled
                  id="standard-basic"
                  name="email"
                  value={form.email}
                  onChange={handleChange}
                  label="Email"
                  variant="outlined"
                />
                <TextField
                  id="standard-basic"
                  name="date_of_birth"
                  value={form.location}
                  onChange={handleChange}
                  label="Location"
                  variant="outlined"
                />
                <div style={{ textAlign: "right" }}>
                  <ButtonStyled
                    text="black"
                    bg="white"
                    onClick={() => handleClose()}
                  >
                    Cancel
                  </ButtonStyled>
                  <ButtonStyled onClick={() => handleSave(form)}>
                    Save
                  </ButtonStyled>
                </div>
              </form>
            </CustomForm>
          </MuiPickersUtilsProvider>
        </CustomDiv>
      </Layout>
    </>
  )
}
export default UpdateEducatorInfoPage

//styled-components
const CustomForm = styled.div`
  padding-top: 5px;
  .MuiFormGroup-root {
    padding: 0 10px;
  }
  .MuiTextField-root {
    width: 100%;
    margin: 10px;
  }
  form {
    margin: 0 25%;
  }
  form label,
  input,
  h2,
  label,
  span,
  input,
  textarea {
    font-size: 16px;
    font-family: "GT-Walsheim-Regular", sans-serif;
  }
  .MuiDialog-paper {
    min-width: 20%;
  }
  svg {
    width: 0.6em;
  }
`

const CustomTr = styled.tr`
  background-color: black;
  color: white;
`
const CustomDiv = styled.div`
  margin: 10px 10px 40px 10px;
  // height: 100vh;
  // border: 1px solid grey;
  span {
    // margin: 30px;
  }
  .MuiCircularProgress-root {
    margin: auto 50%;
    color: black;
  }
`
